<template>
  <div>
    <div
      v-if="permissions['containers.index'] == false"
      style="height: 50vh"
      class="d-flex align-center justify-center"
    >
      <h1 class="display-2 text-center">
        You are not allowed to view this page
      </h1>
    </div>
    <div v-else class="home">
      <v-container fluid class="bg-white mt-65">
        <div class="d-flex pb-4">
          <h1 class="primetime">Containers</h1>
          <div class="ml-auto mr-4" cols="12" md="2">
            <!-- <manage @dataUpdated="getData()" :id="editId" :show="formShow" @cancelForm="formShow = false"/> -->
          </div>
        </div>
        <v-row>
          <v-dialog v-model="dialog" scrollable max-width="85%" persistent>
            <v-card>
              <v-card-title>
                <v-btn
                  class="ml-auto"
                  color="blue darken-1"
                  text
                  @click="cancel"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-container v-if="modalData">
                <div class="vehicle">
                  <v-container
                    class="white d-flex justify-space-between align-center"
                  >
                    <h3 class="primetime">Container Information</h3>
                  </v-container>
                  <v-container class="white mt-1">
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-row no-gutters align="center" class="table-wrapper">
                          <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                            Status
                          </v-col>
                          <v-col cols="12" sm="6" class="pa-3 second-column">
                            {{ modalData.status_name }}
                          </v-col>
                        </v-row>
                        <v-row no-gutters align="center" class="table-wrapper">
                          <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                            Booking Number
                          </v-col>
                          <v-col cols="12" sm="6" class="pa-3 second-column">
                            {{ modalData.booking_number }}
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-row no-gutters align="center" class="table-wrapper">
                          <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                            Container Number
                          </v-col>
                          <v-col cols="12" sm="6" class="pa-3 second-column">
                            {{ modalData.container_number }}
                          </v-col>
                        </v-row>
                        <v-row no-gutters align="center" class="table-wrapper">
                          <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                            AR number
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            class="pa-3 second-column"
                            v-if="permissions['exports.view'] === true"
                          >
                            <router-link
                              style="text-decoration: none"
                              :to="`/exports/${modalData.id}`"
                            >
                              {{ modalData.ar_number }}
                            </router-link>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
                <div class="vehicle">
                  <v-container
                    class="white d-flex justify-space-between align-center"
                  >
                    <h3 class="primetime">Vehicle Information</h3>
                  </v-container>
                  <v-container class="white mt-1">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Image</th>
                            <th class="text-left">Year</th>
                            <th class="text-left">Make</th>
                            <th class="text-left">Model</th>
                            <th class="text-left">Color</th>
                            <th class="text-left">Vin</th>
                            <th class="text-left">Lot Number</th>
                            <th class="text-left">Customer Name</th>
                            <th class="text-left">View vehicles</th>
                            <th class="text-left">Invoices</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in modalData.vehicles"
                            :key="index"
                          >
                            <td>
                              <v-img
                                :src="item.photo"
                                v-if="item.photo"
                                max-height="35"
                                max-width="50"
                              ></v-img>
                            </td>
                            <td>{{ item.year }}</td>
                            <td>{{ item.make }}</td>
                            <td>{{ item.model }}</td>
                            <td>{{ item.color }}</td>
                            <td>
                              <router-link
                                style="text-decoration: none"
                                :to="`/vehicles/${item.id}`"
                              >
                                {{ item.vin }}
                              </router-link>
                            </td>
                            <td>
                              <manage-details
                                :lot="true"
                                :text="item.lot_number"
                                :id="item.id"
                              />
                            </td>
                            <td>{{ item.customer_name }}</td>
                            <td>
                              <router-link
                                class="dashboard-link"
                                :to="`vehicles/${item.id}`"
                              >
                                vehicle
                              </router-link>
                            </td>
                            <td>
                              <div
                                v-for="(invoice, i) in item.invoice_photos"
                                :key="i"
                              >
                                <v-btn
                                  small
                                  class="ma-1"
                                  @click="invoiceUrl(invoice.url)"
                                  >{{ invoice.label }}</v-btn
                                >
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-container>
                </div>
              </v-container>
            </v-card>
          </v-dialog>

          <v-col cols="12" md="10" class="pb-0 pt-0">
            <div>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    outlined
                    v-model="filters.export_global_search"
                    label="Global Search"
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-btn
                    :loading="filtering"
                    color="primary"
                    @click="manualSearch"
                  >
                    Search
                  </v-btn>
                  <!--<v-btn :loading="filtering" color="primary" class="buttonleftclass" @click="exportConsignee">-->
                  <!--Export-->
                  <!--</v-btn>-->
                </v-col>

                <v-col cols="12" md="3" class="text-right">
                  <h5>
                    Showing : {{ this.showingFrom ? this.showingFrom : "0" }} -
                    {{ this.showingTo ? this.showingTo : "0" }} of
                    {{ this.count ? this.count : "0" }}
                  </h5>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <!--<confirmation :confirming="deletingId" @confirmed="remove" :show="deleteConfirmationShow"-->
        <!--@canceled="deleteConfirmationShow = false"/>-->

        <v-simple-table
          class="mt-10"
          v-if="loading === false && containers.data"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">AR Number</th>
                <th class="text-left">Photos</th>

                <th class="text-left">Container Number</th>
                <th class="text-left">Booking Number</th>
                <th class="text-left">B/L</th>
                <th
                  @click="Sort('created_at')"
                  class="text-left date sortable"
                  style="cursor: pointer"
                >
                  Manifest
                  <v-icon
                    v-if="
                      currentSortDir === 'asc' && currentSort === 'created_at'
                    "
                    >mdi-sort-ascending</v-icon
                  >
                  <v-icon
                    v-if="
                      currentSortDir === 'desc' && currentSort === 'created_at'
                    "
                    >mdi-sort-descending</v-icon
                  >
                </th>
                <th>POD</th>

                <th
                  @click="Sort('loading_date')"
                  class="text-left date sortable"
                  style="cursor: pointer"
                >
                  Loaded Date
                  <v-icon
                    v-if="
                      currentSortDir === 'asc' && currentSort === 'loading_date'
                    "
                    >mdi-sort-ascending</v-icon
                  >
                  <v-icon
                    v-if="
                      currentSortDir === 'desc' &&
                      currentSort === 'loading_date'
                    "
                    >mdi-sort-descending</v-icon
                  >
                </th>
                <th
                  @click="Sort('export_date')"
                  class="text-left date sortable"
                  style="cursor: pointer"
                >
                  Export Date
                  <v-icon
                    v-if="
                      currentSortDir === 'asc' && currentSort === 'export_date'
                    "
                    >mdi-sort-ascending</v-icon
                  >
                  <v-icon
                    v-if="
                      currentSortDir === 'desc' && currentSort === 'export_date'
                    "
                    >mdi-sort-descending</v-icon
                  >
                </th>

                <th
                  @click="Sort('eta')"
                  class="text-left date sortable"
                  style="cursor: pointer"
                >
                  ETA
                  <v-icon
                    v-if="currentSortDir === 'asc' && currentSort === 'eta'"
                    >mdi-sort-ascending</v-icon
                  >
                  <v-icon
                    v-if="currentSortDir === 'desc' && currentSort === 'eta'"
                    >mdi-sort-descending</v-icon
                  >
                </th>

                <th class="text-left" style="min-width: 160px">Invoice</th>
                <th class="text-left">Invoice Amount</th>
                <th class="text-left">Paid Amount</th>
                <th class="text-left">Note</th>
                <th class="text-center">View</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>
                  <v-text-field
                    v-model="filters.ar_number"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </td>
                <td></td>

                <td>
                  <v-text-field
                    v-model="filters.container_number"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="filters.booking_number"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </td>

                <td></td>
                <td>
                  <v-text-field
                    v-model="filters.manifest_date"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </td>

                <td>
                  <v-select
                    item-text="name"
                    item-value="id"
                    :items="locationsItem"
                    v-model="filters.location_id"
                    @change="manualSearch"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </td>

                <td class="py-3">
                  <v-text-field
                    v-model="filters.loading_date"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="filters.export_date"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </td>

                <td>
                  <v-text-field
                    v-model="filters.eta"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </td>

                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    :loading="filtering"
                    @click="manualSearch"
                  >
                    <v-icon dark>mdi-clipboard-text-search</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    :loading="filtering"
                    @click="clearFilter"
                  >
                    <v-icon dark>mdi-close-octagon</v-icon>
                  </v-btn>
                </td>
              </tr>

              <tr v-for="(item, index) in containers.data" :key="index">
                <td>{{ index + 1 + (pagination.current - 1) * 20 }}</td>
                <td v-if="permissions['exports.view'] === true">
                  <router-link
                    style="text-decoration: none"
                    :to="`/exports/${item.id}`"
                  >
                    {{ item.ar_number }}
                  </router-link>
                </td>
                <td v-else>
                  <span>{{ item.ar_number }}</span>
                </td>
                <td>
                  <manage-image
                    :lot="item.photos_count"
                    :text="item.thumbnail"
                    :id="item.id"
                  />
                </td>

                <td>
                  <a
                    v-if="item.container_tracking_url"
                    :href="item.container_tracking_url"
                    style="text-decoration: none"
                    target="_blank"
                  >
                    {{ item.container_number }}
                  </a>
                  <span v-else>
                    {{ item.container_number }}
                  </span>
                </td>
                <td>{{ item.booking_number }}</td>
                <td @click="downLoadBl(item.id)">
                  <router-link style="text-decoration: none" to="">
                    BL
                  </router-link>
                </td>
                <td>{{ item.manifest_date }}</td>
                <td>
                  {{
                    item.vehicles && item.vehicles[0].location
                      ? item.vehicles[0].location
                      : ""
                  }}
                </td>

                <td>{{ item.loading_date }}</td>
                <td>{{ item.export_date }}</td>
                <td>{{ item.eta }}</td>

                <td>
                  <a
                    v-if="item.export_invoice_photo"
                    :href="`${item.export_invoice_photo[0].url}`"
                    class="link-btn mr-1 mt-2"
                    target="_blank"
                    >USA INV</a
                  >

                  <a
                      v-if="item.dxb_inv"
                      :href="`${item.dxb_inv}`"
                      class="link-btn mr-1 mt-2"
                      target="_blank"
                  >DXB INV</a
                  >
                </td>
                <td>
                  {{
                    item.invoice_details
                      ? item.invoice_details.total_amount
                      : ""
                  }}
                </td>
                <td>
                  {{
                    item.invoice_details ? item.invoice_details.paid_amount : ""
                  }}
                </td>
                <td>
                  <textarea
                    @focusout="(e) => noteSave(e, item)"
                    rows="2"
                    style="
                      border: 1px solid #ccc;
                      border-radius: 4px;
                      margin-top: 4px;
                      min-width: 200px;
                      padding: 0 4px;
                    "
                    >{{ item.note }}</textarea
                  >
                </td>
                <td>
                  <div v-if="permissions['containers.view'] == false"></div>
                  <v-btn v-else text @click="showVehicleData(item)">
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!--<div class="text-xs-center" v-else>-->
        <!--<v-btn primary>-->
        <!--<rotate-square5></rotate-square5>-->
        <!--</v-btn>-->
        <!--</div>-->

        <vue-content-loading :width="300" :height="100" v-if="loading === true">
          <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
        </vue-content-loading>

        <v-row justify="center" style="margin: 0">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                class="my-4"
                :total-visible="7"
                circle
                @input="getData(pagination.current, filtersUrl())"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Manage from "@/views/cosignees/Manage.vue";
import Api from "@/services/Api";
import ManageDetails from "@/views/vehicles/ManageDetails.vue";
// import Confirmation from "@/components/Confirmation.vue";
import ManageImage from "@/views/exports/ManageImage.vue";
import { VueContentLoading } from "vue-content-loading";

export default {
  name: "Containers",
  components: {
    // Confirmation,
    // Manage,
    VueContentLoading,
    ManageDetails,
    ManageImage,
  },
  data() {
    return {
      currentSort: "",
      currentSortDir: "asc",
      permissions: JSON.parse(localStorage.getItem("permissions")),
      editId: null,
      deletingId: null,
      formShow: false,
      dialog: false,
      deleteConfirmationShow: false,

      containers: {},
      pagination: {
        current: 1,
        total: 0,
      },
      filters: {},
      locationsItem: [],
      statusItem: [
        { title: "Select Status", id: "" },
        // {title: 'ON HAND', id: 1},
        { title: "MANIFEST", id: 2 },
        // {title: 'ON THE WAY', id: 3},
        { title: "SHIPPED", id: 4 },
        // {title: 'PICKED UP', id: 5},
        { title: "ARRIVED", id: 6 },
      ],
      customarUserId: "",
      loading: false,
      filtering: false,
      customersItem: [],
      modalData: [],
      showingFrom: 0,
      showingTo: 0,
      count: 0,
    };
  },
  methods: {
    Sort(currentsort) {
      this.loading = true;
      this.currentSortDir =
        this.currentSortDir === "asc" && this.currentSort === currentsort
          ? "desc"
          : "asc";
      this.currentSort = currentsort;

      this.filters.page = this.pagination.current;
      this.filters.order_by_column = currentsort;
      this.filters.order_by = this.currentSortDir;

      let totalSortFilterQuery = this.filtersUrl();

      let finalUrl = this.$route.path + `?` + this.filtersUrl();
      window.history.pushState({}, "", finalUrl);

      // Api.get(`/containers?page=${this.pagination.current}?order_by_column=${currentsort}&order_by=${this.currentSortDir}`)
      Api.get(`/containers?${totalSortFilterQuery}`)
        .then((res) => {
          this.containers = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },

    manualSearch() {
      this.filters.page = 1;
      this.search();
    },

    getData(page, searchQuery = "") {
      this.loading = true;
      if (!page) {
        page = this.pagination.current;
      }
      if (searchQuery) {
        searchQuery = `&${searchQuery}`;
      }
      if (this.$route.query && this.$route.query.customer_user_id) {
        searchQuery += `&customer_user_id=${this.$route.query.customer_user_id}`;
        this.filters.customer_user_id = this.$route.query.customer_user_id;
      }

      this.filters.page = page;
      let finalSearchQuery = this.filtersUrl();

      let finalUrl = this.$route.path + `?` + finalSearchQuery;
      window.history.pushState({}, "", finalUrl);

      // Api.get(`/containers?page=${page}${searchQuery}`)
      Api.get(`/containers?${finalSearchQuery}`)
        .then((res) => {
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.containers = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },

    invoiceUrl(e) {
      window.open(e, "_blank");
    },

    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },

    search() {
      let page = this.filters.page ? this.filters.page : 1;
      this.getData(page, this.filtersUrl());
    },
    edit(id) {
      this.editId = id;
      this.formShow = true;
    },
    destroy(id) {
      this.deletingId = id;
      this.deleteConfirmationShow = true;
    },
    remove() {
      Api.delete(`/consignees/${this.deletingId}`)
        .then(() => {
          this.getData(1);
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });

      this.editId = null;
      this.deletingId = null;
      this.formShow = false;
    },
    clearFilter() {
      this.filters = {};
      this.getData(1);
    },
    exportConsignee() {
      this.exportData(this.filtersUrl());
    },
    exportData(searchQuery = "") {
      var url = Api.defaults.baseURL + `/consignees/export/excel`;
      if (searchQuery) {
        searchQuery = `?${searchQuery}`;
        url = Api.defaults.baseURL + `/consignees/export/excel${searchQuery}`;
      }
      window.location = url;
    },

    showVehicleData(objData) {
      this.modalData = [];
      this.modalData = objData;
      //this.$modal.show("vehicle");
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
    },

    getDataWithFilter(searchQuery) {
      Api.get(`/containers?${searchQuery}`)
        .then((res) => {
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.containers = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },

    downLoadBl(id) {
      window.open(
        `${process.env.VUE_APP_API_ENDPOINT}/api/v1/exports/${id}/landing-pdf`,
        "_blank"
      );
    },
    noteSave(e, item) {
      e.preventDefault();
      let value = e.target.value;
      Api.post(`/containers/${item.id}/save-note`, {
        note: value,
      })
        .then(() => {})
        .catch(() => {});
    },
  },
  watch: {
    customarUserId(newVal) {
      if (newVal) {
        this.loading = true;
        Api.get(`/customers-item?status=1&customer_name=${newVal}`)
          .then((res) => {
            this.loading = false;
            this.customersItem = res.data.data;
            // console.log(res);
          })
          .catch((err) => {
            if (err.response.status !== 401) {
              this.$toastr.e("Failed to load data!" + err);
              this.loading = false;
            }
          });
      }
    },
    // "filters.status"() {
    //   this.search();
    // },
  },

  mounted() {
    this.locationsItem = this.$store.state.auth.locations;

    if (Object.keys(this.$route.query).length) {
      this.filters = this.$route.query;
      if (this.filters.status) {
        this.filters.status = parseInt(this.filters.status);
      }

      if (this.filters.order_by_column) {
        this.currentSort = this.filters.order_by_column;
      }
      if (this.filters.order_by) {
        this.currentSortDir = this.filters.order_by;
      }

      this.getDataWithFilter(this.filtersUrl());
    } else {
      this.getData(1);
    }
  },
};
</script>

<style>
.table-wrapper {
  border: 1px solid #dddddd;
}

.second-column {
  border-left: 1px solid #dddddd;
}
.home {
  background-color: #f8f8fb;
}

.buttonleftclass {
  margin-left: 5px;
}
.vm--modal {
  height: max-content !important;
  top: 50px !important;
}

.v-data-table__wrapper {
  overflow-y: auto !important;
}
.date {
  min-width: 107px;
}
th.sortable {
  cursor: pointer;
}
th.sortable:hover {
  color: black;
  text-decoration: underline;
}
.mdi.mdi-sort-descending,
.mdi.mdi-sort-ascending {
  float: right;
}
</style>
