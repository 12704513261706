<template>
  <div>
    <v-row align="center">
      <v-col class="12" sm="3">
        <v-card height="340" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <span class="number-text">{{
              this.vehicles ? this.vehicles : 0
            }}</span>
            <v-card-title class="d-flex flex-column">
              Vehicles
              <v-chip color="#4C66A3" small link dark>
                <v-btn target="_blank" text :to="`/vehicles?customer_name=${this.customer}`">
                  <v-icon>mdi-link</v-icon> view
                </v-btn>
              </v-chip>
            </v-card-title>
          </div>
        </v-card>
      </v-col>
      <v-col class="12" sm="3">
        <v-card height="340" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <span class="number-text">{{
              this.exports ? this.exports : 0
            }}</span>
            <v-card-title class="d-flex flex-column">
              Exports
              <v-chip color="#4C66A3" small link dark>
                <v-btn text target="_blank" :to="`/exports?customer_user_id=${this.customer}&excluded_status=6`">
                  <v-icon>mdi-link</v-icon> view
                </v-btn>
              </v-chip>
            </v-card-title>
          </div>
        </v-card>
      </v-col>

      <v-col class="12" sm="3">
        <v-card height="340" class="mt-3">
          <v-row>
            <v-col cols="12" sm="6">
              <div>
                <v-card-title style="white-space: nowrap;"
                >Invoice Overview</v-card-title
                >
                <v-list style="margin-top: 20px">
                  <v-list-item class="my-n5">
                    <v-list-item-icon class="mr-2">
                      <v-icon color="#34c38f">mdi-circle-medium</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="subtitle-2">Paid ({{ this.invoice.paid }})</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="my-n5">
                    <v-list-item-icon class="mr-2">
                      <v-icon color="#6087e0">mdi-circle-medium</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="subtitle-2">Partially ({{ this.invoice.partially }})</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="my-n5">
                    <v-list-item-icon class="mr-2">
                      <v-icon color="#f46a69">mdi-circle-medium</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="subtitle-2">Unpaid ({{ this.invoice.unpaid }})</v-list-item-title>
                  </v-list-item>
                </v-list>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="chart-container mt-5">
                <Doughnut
                        ref="invoiceChart"
                        :height="200"
                        :width="200"
                        :chartData="secondChartData"
                        :getTotal="totalInvoice"
                ></Doughnut>

              </div>
            </v-col>
            <!-- </div> -->
          </v-row>
        </v-card>
      </v-col>

      <v-col class="12" sm="3">
        <v-card height="340" class="mt-3">
          <v-row>
            <v-col cols="12" sm="12">
              <div>
                <v-card-title
                >Customer Details</v-card-title
                >
                <v-list>

                  <v-list-item class="">
                    <div class="row">
                      <div class="col-8">ALL CONTAINER</div>
                      <div class="col-4 text-right">
                        <router-link :to="`/exports?customer_user_id=${this.customer}`">
                          view
                        </router-link>
                      </div>
                    </div>
                  </v-list-item>

                  <v-list-item class="">
                    <div class="row">
                      <div class="col-8">ALL INVOICES</div>
                      <div class="col-4 text-right">
                        <router-link :to="`/invoices/${this.customer}`">
                          view
                        </router-link>
                      </div>
                    </div>
                  </v-list-item>

                  <v-list-item class="">
                    <div class="row">
                      <div class="col-8">PAID INVOICES</div>
                      <div class="col-4 text-right">
                        <router-link :to="`/invoice/paid?customer_user_id=${this.customer}`">
                          view
                        </router-link>
                      </div>
                    </div>
                  </v-list-item>

                  <v-list-item class="">
                    <div class="row">
                      <div class="col-8">PARTIAL PAID</div>
                      <div class="col-4 text-right">
                        <router-link :to="`/invoice/partial_paid?customer_user_id=${this.customer}`">
                          view
                        </router-link>
                      </div>
                    </div>
                  </v-list-item>

                  <v-list-item class="">
                    <div class="row">
                      <div class="col-8">UNPAID INVOICES</div>
                      <div class="col-4 text-right">
                        <router-link :to="`/invoice/unpaid?customer_user_id=${this.customer}`">
                          view
                        </router-link>
                      </div>
                    </div>
                  </v-list-item>

                </v-list>
              </div>
            </v-col>
            <!-- </div> -->
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="12" sm="12">
        <v-container
          class="white mt-5 pl-6 d-flex justify-space-between align-center"
        >
          <h3 class="primetime">Consigneee / Notifying Party</h3>
          <!--<v-btn text color="grey"><v-icon>mdi-pencil</v-icon></v-btn>-->
        </v-container>
        <v-container class="white mt-1 pt-5 px-5">
          <v-row>
            <v-col
              cols="6"
              sm="6"
              md="6"
              v-for="(item, index) in this.consignee"
              :key="index"
            >
              <v-row no-gutters align="center" class="table-wrapper">
                <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                  Name
                </v-col>
                <v-col cols="12" sm="6" class="pa-3 second-column">
                  {{ item.consignee_name ? item.consignee_name : "N/A" }}
                </v-col>
              </v-row>

              <v-row no-gutters align="center" class="table-wrapper">
                <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                  Address
                </v-col>
                <v-col cols="12" sm="6" class="pa-3 second-column">
                  {{
                    item.consignee_address_1 ? item.consignee_address_1 : "N/A"
                  }}
                </v-col>
              </v-row>

              <v-row no-gutters align="center" class="table-wrapper">
                <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                  City
                </v-col>
                <v-col cols="12" sm="6" class="pa-3 second-column">
                  {{ item.city ? item.city : "N/A" }}
                </v-col>
              </v-row>

              <v-row no-gutters align="center" class="table-wrapper">
                <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                  Country
                </v-col>
                <v-col cols="12" sm="6" class="pa-3 second-column">
                  {{ item.country ? item.country : "N/A" }}
                </v-col>
              </v-row>

              <v-row no-gutters align="center" class="table-wrapper">
                <v-col cols="12" sm="6" class="pa-3 font-weight-bold">
                  Phone
                </v-col>
                <v-col cols="12" sm="6" class="pa-3 second-column">
                  {{ item.phone ? item.phone : "N/A" }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InvoiceChart from "./InvoiceChart.vue";
import Api from "@/services/Api";
import Doughnut from "@/components/charts/Doughnut.vue";


export default {
  components: { InvoiceChart, Doughnut },
  props: ["consignee", "vehicles", "exports", "customer", "invoice"],
  data() {
    return {
      report: {},
      locations: [],
      loading: false,
      locationsLoading: false,
      location_id: null,

      totalInvoice: 0,
      secondChartData: {
        hoverBackgroundColor: "red",
        radius: 1,
        hoverBorderWidth: 10,
        labels: ["Paid", "Partially", "Unpaid"],
        datasets: [
          {
            label: ["Invoice Overview"],
            backgroundColor: ["#34c38f", "#6087e0", "#f46a69"],
            data: []
          }
        ]
      }

    };
  },
  methods: {
    showVehiclesPage(status) {
      this.$router.push({
        name: "Vehicles",
        query: {
          location: this.location_id,
          status: status,
        },
      });
    },
    filter(location_id) {
      this.location_id = location_id;
      this.getData();
    },
    getLocations(page = 1) {
      this.locationsLoading = true;
      Api.get(`/settings/locations?page=${page}`)
        .then((res) => {
          this.locations = res.data.data;
          this.locationsLoading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data!" + err);
          this.locationsLoading = false;
          }else{
            this.$toastr.e(err.response.data.status)
          }
        });
    },
    getData() {
      let url = `/dashboard`;
      if (this.location_id) {
        url = `/dashboard?location_id=${this.location_id}`;
      }
      this.loading = true;
      Api.get(url)
        .then((res) => {
          this.report = res.data;
          this.loading = false;
          
        })
        .catch((err) => {
          if (err.response.status !== 401) {
          this.$toastr.e("Failed to load data!" + err);
          this.loading = false;
          }
        });
    },

    generateChartData() {
      this.fillInvoiceChart();
    },

    fillInvoiceChart() {
      this.secondChartData.datasets[0].data = [];
      this.secondChartData.datasets[0].data = [

        // this.report.invoice_overview.paid ? this.report.invoice_overview.paid : 0,
        // this.report.invoice_overview.partially ? this.report.invoice_overview.partially : 0,
        // this.report.invoice_overview.unpaid ? this.report.invoice_overview.unpaid : 0

        this.invoice.paid ? this.invoice.paid : 0,
        this.invoice.partially ? this.invoice.partially : 0,
        this.invoice.unpaid ? this.invoice.unpaid : 0
      ];
      // this.totalInvoice = this.report.invoice_overview.total;
      this.totalInvoice = this.invoice.total;
      this.$refs['invoiceChart'].render();
      this.$refs['invoiceChart'].update();
    }


  },
  mounted() {
      this.totalInvoice = this.invoice.total;
      this.generateChartData();
  },
  watch: {
    invoice: function () {
      this.generateChartData();
    }
  }
};
</script>

<style scoped>
.number-text {
  font-family: serif;
  font-size: 4rem;
  color: #666666;
}

.table-wrapper {
  border: 1px solid #dddddd;
}

.second-column {
  border-left: 1px solid #dddddd;
}
</style>
