<template>
  <v-app id="app">
    <v-app-bar app color="#2ba5b6" dark>
      <div class="container-fluid">
        <v-row>
          <v-col cols="4" md="4">
            <!-- <div style=""> -->
            <router-link to="/dashboard">
              <v-img :src="logo_url" width="115px"></v-img>
            </router-link>
            <!-- </div> -->
          </v-col>
          <v-col cols="8" md="8" class="text-right">
            <!-- <div style=""> -->
            <LanguageSwitcher class="mx-1"></LanguageSwitcher>
            <v-menu v-if="getRole">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="d-inline-block ma-1 ml-4"
                  link
                  v-bind="attrs"
                  v-on="on"
                >
                  Setting
                  <v-icon>mdi-cog-outline</v-icon>
                </div>
              </template>
              <v-list>
                <v-list-item
                  v-if="permissions['locations.index'] == true"
                  to="/settings/locations"
                  @click="(e) => linkHandelar(e)"
                >
                  <v-list-item-title>Locations</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="permissions['countries.index'] == true"
                  to="/settings/countrys"
                  @click="(e) => linkHandelar(e)"
                >
                  <v-list-item-title>Country </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="permissions['states.index'] == true"
                  to="/settings/states"
                  @click="(e) => linkHandelar(e)"
                >
                  <v-list-item-title>State</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="permissions['cities.index'] == true"
                  to="/settings/citys"
                  @click="(e) => linkHandelar(e)"
                >
                  <v-list-item-title>City</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="roleId === 0"
                  to="/pages"
                  @click="(e) => linkHandelar(e)"
                >
                  <v-list-item-title>Pages</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="roleId === 0"
                  to="/acl/roles"
                  @click="(e) => linkHandelar(e)"
                >
                  <v-list-item-title>{{ $t("menus.roles") }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="roleId === 0"
                  to="/acl/users"
                  @click="(e) => linkHandelar(e)"
                >
                  <v-list-item-title>User Management</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="roleId === 0"
                  to="/ports"
                  @click="(e) => linkHandelar(e)"
                >
                  <v-list-item-title>Ports</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="roleId === 0"
                  to="/audits"
                  @click="(e) => linkHandelar(e)"
                >
                  <v-list-item-title>Audit Logs</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn to="/checkout" text v-if="permissions['exports.store']">
              <v-icon>mdi-cart </v-icon>
              <span class="bg-dark text-white">{{
                this.$store.state.auth.user.cartCount
              }}</span>
            </v-btn>
            <!-- <v-btn to="/notifications" text>
                Notification
                <v-icon>mdi-bell-outline</v-icon>
              </v-btn> -->
            <v-menu
              max-height="60vh"
              max-width="406px"
              transition="slide-y-transition"
              v-if="roleId !== 8"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="d-inline-block ma-1 ml-4 notification-section"
                  v-bind="attrs"
                  v-on="on"
                >
                  Notification

                  <v-icon>mdi-bell-outline</v-icon>
                  <p class="notification-counter">{{ notification_count }}</p>
                </div>
              </template>
              <!-- <v-list> -->
              <v-list dense>
                <div class="mt-5 mb-0 ml-3 mr-3 d-flex justify-space-between">
                  <p>Notification</p>
                  <!-- <a href="/notifications" style="font-size: 12px;">ALL NOTIFICATION</a> -->
                  <router-link to="/notifications" style="font-size: 12px"
                    >ALL NOTIFICATION</router-link
                  >
                </div>
                <v-divider></v-divider>
              </v-list>
              <v-list
                style="padding: 2px !important"
                v-for="x in notification"
                :key="`x-key-${x.id}`"
              >
                <v-subheader
                  class="subheader"
                  style="min-height: 40px; font-size: 12px"
                  v-on:click="redirectUrl(x.id)"
                >
                  <strong style="display: inline-block"
                    >{{ x.subject }} <br /><span
                      style="
                        display: inline-block;
                        font-size: 10px;
                        color: #8a8a8a;
                      "
                      >DATE: {{ x.created_at }}</span
                    ></strong
                  >
                </v-subheader>
                <v-divider></v-divider>
              </v-list>
              <!--                <v-list dense>
                    <v-subheader><v-btn to="/notifications">read all</v-btn></v-subheader>
                </v-list>-->
            </v-menu>
            <!-- <v-btn @click="logout" target="_blank" text>
                <v-icon>mdi-logout</v-icon>
              </v-btn> -->
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <div class="d-inline-block" link v-bind="attrs" v-on="on">
                  <v-avatar size="45" class="ml-2 hidden-md-and-down">
                    <img :src="`${photo}`" alt="User" />
                  </v-avatar>
                </div>
              </template>
              <v-list>
                <v-list-item to="/profile">
                  <v-list-item-title>Profile</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout">
                  <v-list-item-title>Logout </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-app-bar-nav-icon
              class="hidden-lg-and-up"
              @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
            <!-- </div> -->
          </v-col>
        </v-row>

        <!-- <v-spacer></v-spacer> -->
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      style="height: 47%"
    >
      <v-list nav dense>
        <v-list-item>
          <v-list-item-avatar>
            <img :src="`${photo}`" alt="John" />
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item>
            <v-list-item-title>
              <v-chip
                link
                to="/"
                class="nav-button"
                color="white"
                label
                text-color="gray"
              >
                <v-icon left color="gray">mdi-home-outline</v-icon>Home
              </v-chip>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <v-chip
                v-if="permissions['customers.index'] == true"
                link
                to="/customers"
                class="ma-1"
                color="white"
                label
                text-color="gray"
              >
                <v-icon left color="gray">mdi-account-multiple-outline</v-icon
                >Customer
              </v-chip>
            </v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>
              <v-chip
                v-if="permissions['consignees.index'] == true"
                link
                to="/cosignees"
                class="ma-1"
                color="white"
                label
                text-color="gray"
              >
                <v-icon left color="gray">mdi-account-outline</v-icon>Cosignee
              </v-chip>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-chip
                v-if="permissions['vehicles.index'] == true"
                link
                class=""
                to="/vehicles"
                color="white"
                label
                text-color="gray"
              >
                <v-icon left color="gray">mdi-car-outline</v-icon>Vehicle
              </v-chip>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-chip
                v-if="permissions['containers.index'] == true"
                link
                to="/containers"
                class="ma-1"
                color="white"
                label
                text-color="gray"
              >
                <v-icon left color="gray">mdi-view-module-outline</v-icon
                >Container
              </v-chip>
            </v-list-item-title> </v-list-item
          ><v-list-item>
            <v-list-item-title>
              <v-chip
                v-if="permissions['exports.index'] == true"
                link
                to="/exports"
                class="ma-1"
                color="white"
                label
                text-color="gray"
              >
                <v-icon left color="gray">mdi-earth</v-icon>Export
              </v-chip>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-chip
                v-if="permissions['prices.index'] == true"
                link
                to="/prices"
                class="ma-1"
                color="white"
                label
                text-color="gray"
              >
                <v-icon left color="gray">mdi-currency-usd</v-icon>Price
              </v-chip>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-group prepend-icon="mdi-file-document">
              <template v-slot:activator>
                <v-list-item-title>Reporting</v-list-item-title>
              </template>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-chip
                      to="/reports/vehicle"
                      class=""
                      color="white"
                      label
                      text-color="gray"
                    >
                      Vehicle Report
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-title>
                    <v-chip
                      to="/reports/container"
                      class=""
                      color="white"
                      label
                      text-color="gray"
                    >
                      Container Report
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-title>
                    <v-chip
                      to="/reports/customer"
                      class=""
                      color="white"
                      label
                      text-color="gray"
                    >
                      Customer Report
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-title>
                    <v-chip
                      to="/reports/customer-record"
                      class=""
                      color="white"
                      label
                      text-color="gray"
                    >
                      Customer Record Report
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-title>
                    <v-chip
                      to="/reports/customer-invoice-record"
                      class=""
                      color="white"
                      label
                      text-color="gray"
                    >
                      Customer Invoice Report
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-title>
                    <v-chip
                      to="/reports/customer-title-status"
                      class=""
                      color="white"
                      label
                      text-color="gray"
                    >
                      Customer Title Status
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-title>
                    <v-chip
                      to="/reports/customer-management"
                      class=""
                      color="white"
                      label
                      text-color="gray"
                    >
                      Customer Management Report
                    </v-chip>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item>
          <v-list-item
            v-if="
              (roleId == 0 || roleId == 3 || roleId == 5) &&
              permissions['invoices.index'] == true
            "
          >
            <v-list-group prepend-icon="mdi-file-cabinet">
              <template v-slot:activator>
                <v-list-item-title>Accounting</v-list-item-title>
              </template>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-chip
                      to="/invoices-summary"
                      class=""
                      color="white"
                      label
                      text-color="gray"
                    >
                      Invoice
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-title
                    v-if="permissions['invoices.paid_invoice'] == true"
                  >
                    <v-chip
                      to="/invoice/paid"
                      class=""
                      color="white"
                      label
                      text-color="gray"
                    >
                      Paid Invoices
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-title
                    v-if="
                      permissions['permissions.partially_paid_invoice'] == true
                    "
                  >
                    <v-chip
                      to="/invoice/partial_paid"
                      class=""
                      color="white"
                      label
                      text-color="gray"
                    >
                      Partial Paid Invoices
                    </v-chip>
                  </v-list-item-title>
                  <v-list-item-title
                    v-if="permissions['invoices.unpaid_invoice'] == true"
                  >
                    <v-chip
                      to="/invoice/unpaid"
                      class=""
                      color="white"
                      label
                      text-color="gray"
                    >
                      Unpaid Invoice
                    </v-chip>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main class="">
      <NavBar class="hidden-md-and-down" />
      <router-view> </router-view>
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
// import Home from "./views/Home";
import NavBar from "@/components/NavBar.vue";
import axios from "axios";
import Footer from "./components/Footer.vue";
import LanguageSwitcher from "./components/LanguageSwitcher.vue";

export default {
  name: "App",
  components: {
    NavBar,
    Footer,
    LanguageSwitcher,
  },

  data: () => ({
    roleId: null,
    permissions: JSON.parse(localStorage.getItem("permissions")),
    drawer: false,
    photo: "",
    notification: {},
    isSetting: null,
    AuthStr: "Bearer " + localStorage.getItem("authtoken"),
    baseDomain: process.env.VUE_APP_API_ENDPOINT,
    logo_url: `${process.env.VUE_APP_API_ENDPOINT}/images/logo.jpg`,
  }),
  methods: {
    linkHandelar(e) {
      // this.activeMenu = currentMenu;
      // this.$router.go();
      let path = e.target.parentElement.getAttribute("href");

      if(e.metaKey || e.ctrlKey) {
        let routeData = this.$router.resolve({path: path});
        window.open(routeData.href, "_blank");
      }else{
       this.$router.go();
      }
    },
    async logout() {
      await this.$store.dispatch("auth/logout").then(() => {
        localStorage.removeItem("authtoken");
        this.$toastr.s("Logout Successful!");
        this.$router.push("/login");
      });
    },
    getData() {
      this.photo = JSON.parse(localStorage.getItem("user")).photo;
    },

    getNotification() {
      // Api.get(`/notifications?page=1`)
      //     .then((res) => {
      //    this.notification = res.data.data
      //  })
      //  .catch((err) => {
      //    if (err.response.status !== 401) {
      //    this.$toastr.e("Failed to load notification data!" + err);}else{
      //      // this.$toastr.e(err.response.data.status)
      //    }

      const AuthStrWithToken = this.AuthStr;
      const baseDomain = this.baseDomain;
      axios
        .get(`${baseDomain}/api/v1/notifications?page=1`, {
          headers: { Authorization: AuthStrWithToken },
        })
        .then((res) => {
          this.notification = res.data.data;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load notification data!" + err);
          } else {
            // this.$toastr.e(err.response.data.status)
          }
        });
    },
    cart() {
      // this.countcart = Object.keys(JSON.parse(localStorage.getItem("asl_checkout"))).length;
      // return state.user.cartCount
    },

    redirectUrl(id) {
      this.$router.push("/notification/" + id);
    },
  },

  computed: {
    getRole() {
      var roleId = JSON.parse(localStorage.getItem("user")).role;
      this.roleId = roleId;
      if (roleId === 0 || roleId === 1) {
        this.isSetting = true;
      } else {
        this.isSetting = false;
      }

      return this.isSetting;
    },
    notification_count() {
      return this.$store.getters["counter/counter"].notification_count;
    },
  },
  mounted() {
    this.getData();
    this.getNotification();
    // this.cart();
  },
};
</script>
<style scoped>
.home {
  background: #01304d;
}
.subheader:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
<style>
.mylistnotif {
  height: 60vh;
}
div#list-item-315,
div#list-item-316,
div#list-item-317,
div#list-item-318,
div#list-item-319,
div#list-item-320 {
  cursor: pointer;
}

.notification-section {
  position: relative;
}
.notification-counter {
  position: absolute;
  right: -7px;
  top: -10px;
  text-align: center;
  color: white;
  min-width: 19px;
  background: #555;
  border-radius: 5px;
  min-height: 16px;
  font-size: 10px;
  padding: 2px;
}
</style>
