<template>
  <div id="navS" class="navbar sticky">
    <v-container>
      <div>
        <v-chip
          link
          to="/dashboard"
          @click="(e) => linkHandelar(e, $t('menus.home'))"
          class="ma-1 navbar"
          color="blue"
          label
          text-color="gray"
        >
          <v-icon left color="gray">mdi-home-outline</v-icon
          >{{ $t("menus.home") }}
        </v-chip>
        <v-chip
          v-if="permissions['customers.index'] == true"
          link
          to="/customers"
          @click="(e) => linkHandelar(e, 'Customers')"
          class="ma-1"
          color="blue"
          label
          text-color="gray"
        >
          <v-icon left color="gray">mdi-account-multiple-outline</v-icon
          >{{ $t("menus.customers") }}
        </v-chip>
        <!-- <v-div v-if="permissions['consignee.index'] == false"></v-div> -->
        <v-chip
          v-if="permissions['consignees.index'] == true"
          link
          to="/cosignees"
          @click="(e) => linkHandelar(e, $t('menus.consignee'))"
          class="ma-1"
          color="blue"
          label
          text-color="gray"
        >
          <v-icon left color="gray">mdi-account-outline</v-icon
          >{{ $t("menus.consignee") }}
        </v-chip>
        <!-- <v-div v-if="permissions['vehicles.index'] == false"></v-div> -->
        <v-chip
          v-if="permissions['vehicles.index'] == true"
          link
          to="/vehicles"
          @click="(e) => linkHandelar(e, $t('menus.vehicles'))"
          class="ma-1"
          color="blue"
          label
          text-color="gray"
        >
          <v-icon left color="gray">mdi-car-outline</v-icon>
          {{ $t("menus.vehicles") }}
        </v-chip>
        <!-- <v-div v-if="permissions['containers.index'] == false"></v-div> -->
        <v-chip
          v-if="permissions['containers.index'] == true"
          link
          to="/containers"
          @click="(e) => linkHandelar(e, $t('menus.container'))"
          class="ma-1"
          color="blue"
          label
          text-color="gray"
        >
          <v-icon left color="gray">mdi-view-module-outline</v-icon
          >{{ $t("menus.container") }}
        </v-chip>
        <!-- <v-div v-if="permissions['exports.index'] == false"></v-div> -->
        <v-chip
          v-if="permissions['exports.index'] == true"
          link
          to="/exports"
          @click="(e) => linkHandelar(e, $t('menus.export'))"
          class="ma-1"
          color="blue"
          label
          text-color="gray"
        >
          <v-icon left color="gray">mdi-earth</v-icon>{{ $t("menus.export") }}
        </v-chip>
        <!-- <v-div v-if="permissions['prices.index'] == false"></v-div> -->
        <v-chip
          v-if="permissions['prices.index'] == true"
          link
          to="/prices"
          @click="(e) => linkHandelar(e, $t('menus.price'))"
          class="ma-1"
          color="blue"
          label
          text-color="gray"
        >
          <v-icon left color="gray">mdi-currency-usd</v-icon>
          {{ $t("menus.price") }}
        </v-chip>
<!--        <v-menu v-if="roleId !== 3 && roleId !== 4 && roleId !== 7 && roleId !== 8 && roleId !== 2">-->
        <v-menu v-if="permissions['reports.index'] == true && roleId !== 3">
          <template v-slot:activator="{ on, attrs }">
            <div
              class="d-inline-block ma-1 custome-color"
              link
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left color="gray">mdi-file-document</v-icon>
              {{ $t("menus.reporting") }}
              <v-icon>mdi-chevron-down</v-icon>
            </div>
          </template>
          <v-list>
            <v-list-item
              @click="(e) => linkHandelar(e, $t('menus.vehicle_report'))"
              to="/reports/vehicle"
              v-if="$can('view-vehicle-report')"
              ><v-list-item-title>{{
                $t("menus.vehicle_report")
              }}</v-list-item-title></v-list-item
            >
            <v-list-item
              @click="(e) => linkHandelar(e, $t('menus.container_report'))"
              to="/reports/container"
              v-if="$can('view-container-report')"
              ><v-list-item-title>{{
                $t("menus.container_report")
              }}</v-list-item-title></v-list-item
            >
            <v-list-item
              @click="(e) => linkHandelar(e, $t('menus.customer_report'))"
              to="/reports/customer"
              v-if="$can('view-customer-report')"
              ><v-list-item-title>{{
                $t("menus.customer_report")
              }}</v-list-item-title></v-list-item
            >
            <v-list-item
              @click="(e) => linkHandelar(e, $t('menus.customer_record_report'))"
              to="/reports/customer-record"
              v-if="$can('view-customer-record-report')"
              ><v-list-item-title>{{
                $t("menus.customer_record_report")
              }}</v-list-item-title></v-list-item
            >
            <v-list-item
              @click="(e) => linkHandelar(e, $t('menus.customer_invoice_report'))"
              to="/reports/customer-invoice-record"
              v-if="$can('view-customer-invoice-record-report')"
              ><v-list-item-title>{{
                $t("menus.customer_invoice_report")
              }}</v-list-item-title></v-list-item
            >
            <v-list-item
              @click="(e) => linkHandelar(e, $t('menus.customer_title_status'))"
              to="/reports/customer-title-status"
              v-if="$can('view-customer-title-status-report')"
              ><v-list-item-title>{{
                $t("menus.customer_title_status")
              }}</v-list-item-title></v-list-item
            >
            <v-list-item
              @click="(e) => linkHandelar(e, $t('menus.customer_management_report'))"
              to="/reports/customer-management"
              v-if="$can('view-customer-management-report')"
              ><v-list-item-title>{{
                $t("menus.customer_management_report")
              }}</v-list-item-title></v-list-item
            >
          </v-list>
        </v-menu>

        <!--<v-chip link to="/accounting" class="ma-2" color="blue" label text-color="gray">-->
        <!--<v-icon left color="gray">mdi-file-cabinet</v-icon>{{ $t('menus.accounting') }}-->
        <!--</v-chip>-->

        <v-menu
          v-if="
            permissions['invoices.index'] ||
            permissions['invoices.paid_invoice'] ||
            permissions['invoices.partially_paid_invoice'] ||
            permissions['invoices.unpaid_invoice']
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              class="d-inline-block ma-1 custome-color"
              link
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left color="gray">mdi-file-cabinet</v-icon
              >{{ $t("menus.accounting") }}
              <v-icon>mdi-chevron-down</v-icon>
            </div>
          </template>

          <v-list>
            <v-list-item
              v-if="permissions['invoices.index'] == true && roleId != 3"
              to="/invoices-summary"
              @click="(e) => linkHandelar(e, '')"
            >
              <v-list-item-title>Invoices</v-list-item-title>
            </v-list-item>

            <v-list-item
              @click="(e) => linkHandelar(e, '')"
              v-if="permissions['invoices.index'] == true && roleId == 3"
              :to="`/invoices/${userId}`"
            >
              <v-list-item-title>Invoices</v-list-item-title>
            </v-list-item>

            <v-list-item
              @click="(e) => linkHandelar(e, '')"
              v-if="permissions['invoices.paid_invoice'] == true"
              to="/invoice/paid"
            >
              <v-list-item-title>Paid Invoices</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="(e) => linkHandelar(e, '')"
              v-if="permissions['invoices.partially_paid_invoice'] == true"
              to="/invoice/partial_paid"
            >
              <v-list-item-title>Partial Paid Invoices</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="(e) => linkHandelar(e, '')"
              v-if="permissions['invoices.unpaid_invoice'] == true"
              to="/invoice/unpaid"
            >
              <v-list-item-title>Unpaid Invoices</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <div class="d-inline-block ma-1" link v-bind="attrs" v-on="on">
             <v-icon>mdi-cog-outline</v-icon> {{ $t('menus.settings') }}
              <v-icon>mdi-chevron-down</v-icon>
            </div>
          </template>
          <v-list>
            <v-list-item to="/settings/locations" v-if="$can('locations-settings')">
              <v-list-item-title>{{ $t('menus.locations') }}</v-list-item-title>
            </v-list-item>
            <v-list-item to="/acl/roles" v-if="$can('roles-settings')">
              <v-list-item-title>{{ $t('menus.roles') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        </v-menu> -->
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      permissions: JSON.parse(localStorage.getItem("permissions")),
      roleId: JSON.parse(localStorage.getItem("user")).role,
      userId: JSON.parse(localStorage.getItem("user")).id,
      activeMenu: null,
    };
  },
  created() {
    // Set common data to Vue state from local storage
    this.$store.commit(
      "auth/setVehicleColors",
      JSON.parse(localStorage.getItem("vehicle_colors"))
    );
    this.$store.commit(
      "auth/setVehicleConditionItems",
      JSON.parse(localStorage.getItem("vehicle_condition_items"))
    );
    this.$store.commit(
      "auth/setLocations",
      JSON.parse(localStorage.getItem("locations"))
    );
    this.$store.commit(
      "auth/setVehicleCheckboxItems",
      JSON.parse(localStorage.getItem("vehicle_checkbox_items"))
    );
  },
  methods: {
    linkHandelar(e, currentMenu) {
      if (this.activeMenu === currentMenu) {
        this.$router.go();
      }
      this.activeMenu = currentMenu;
      // let path = e.target.parentElement.getAttribute("href");
      //
      // if(e.metaKey || e.ctrlKey) {
      //   let routeData = this.$router.resolve({path: path});
      //   window.open(routeData.href, "_blank");
      // }else{
      //  this.$router.go();
      // }

    },
    linkHandelar2() {
      this.$router.go();
    },

    // Router.prototype.open = function (routeObject) {
    //   const {href} = this.resolve(routeObject)
    //   window.open(href, '_blank')
    // },


    scroll() {
      let scrollpos = window.scrollY;
      const header = document.getElementById("navS");
      const header_height = header.offsetHeight;

      window.addEventListener("scroll", function () {
        scrollpos = window.scrollY;

        if (scrollpos >= header_height) {
          header.classList.add("sticky");
        }
        // else { header.classList.remove("sticky") }
      });
    },
  },
  mounted() {
    // this.scroll();
    console.log(this.$route.name);
    this.activeMenu = this.$route.name;
  },
};
</script>

<style>
.navbar {
  background-color: #01304d !important;
}

.sticky {
  position: fixed;
  background-color: #01304d !important;
  width: 100%;
  z-index: 7;
}
.theme--light.v-chip {
  background-color: #01304d !important;
  color: white;
}
i.v-icon.notranslate.v-icon--left.mdi.mdi-file-document.theme--light.gray--text,
i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light,
i.v-icon.notranslate.v-icon--left.mdi.mdi-file-cabinet.theme--light.gray--text,
.custome-color {
  /* color: white; */
  background-color: #ffffff !important;
  border-color: #2196f3 !important;
  color: black;
  padding: 2.5px;
  border-radius: 4px;
}
.v-application .blue {
  background-color: #ffffff !important;
  border-color: #2196f3 !important;
  color: black;
}
</style>
